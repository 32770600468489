import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect as RedirectRoute } from 'react-router-dom';
import type { RouterHistory, Location, Redirect } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';
import queryString from 'query-string';

import requiresAuth from 'utils/hoc/requiresAuth';
import requiresPlan from 'utils/hoc/requiresPlan';
import withBaseElements from 'utils/hoc/withBaseElements';
import { useUser } from 'utils/contexts/app-context';
import EnableTwoFA from 'components/EnableTwoFA';
import { AUTH, PUBLIC, PRIVATE, ADMIN } from './routes';

const Login = lazy(() => import('containers/login'));
const Activate = lazy(() => import('containers/activate'));
const ResetPassword = lazy(() => import('containers/reset-password'));
// const UpdatePassword = lazy(() => import('containers/update-password'));


const Profile = lazy(() => import('containers/profile'));
const Report = lazy(() => import('containers/report-page/index.tsx'));
// const LEVReport = lazy(() => import('containers/lev/Report'))

const Company = lazy(() => import('containers/company'));


const Terms = lazy(() => import('containers/terms'));
const Privacy = lazy(() => import('containers/privacy'));


// NEW PAGES
const ValidationsNew = lazy(() => import('../pages/validations'))
const TeamDetails = lazy(() => import('../pages/teams/TeamDetails'))
const Teams = lazy(() => import('../pages/teams'))
const Users = lazy(() => import('../pages/users'))

const IvReport  = lazy(() => import('../pages/individualReport'))
const LEVReport = lazy(() => import('../pages/levReport'))



const switchLanguage = (location: Location, history: RouterHistory) => {
  if (!location) return;

  const params = queryString.parse(location.search);
  if (
    params.language &&
    i18next.language &&
    !i18next.language.includes(params.language)
  ) {
    i18next.changeLanguage(params.language);
    moment.locale(params.language);
    if (location.pathname === '/activate') {
      history.replace(location.pathname + location.search);
    }
    else {
      history.replace(location.pathname + location.search)
    }
  }
};

const AuthRouter = ({ history, location }) => {
  switchLanguage(location, history);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path={AUTH.login} component={Login} />
        {/* <Route path={AUTH.register}>
          <FlagOrRedirect
            authorizedFlags={[FeatureFlags.AllowRegister]}
            to={AUTH.login}
          >
            <Register />
          </FlagOrRedirect>
        </Route> */}
        <Route path={AUTH.activate} component={Activate} />
        <Route path={AUTH.reset_password} component={ResetPassword} />
        {/* <Route path={AUTH.update_password} component={UpdatePassword} /> */}

        <RedirectRoute to={AUTH.login} />
      </Switch>
    </Suspense>
  );
};

const PrivateRouter = withBaseElements(({ history, location }: Props) => {
  switchLanguage(location, history);
  const user = useUser();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path={PRIVATE.profile} component={Profile} />
   
        <Route path={PRIVATE.validations}>
            <ValidationsNew />
        </Route>
        <Route path={`${PRIVATE.report}/:uuid`} component={IvReport} />
        <Route path={`${PRIVATE.lev_report}/:uuid`} component={LEVReport} />
        <Route path={`${PRIVATE.old_report}/:uuid`} component={IvReport} />
        <RedirectRoute to={PRIVATE.validations} />
      </Switch>

      <EnableTwoFA open={user && !user.mfaSeen && user?.company && user?.company?.mfaEnabled && user?.company?.authenticate_mode === 'Default'} />
    </Suspense>
  );

});

const AdminRouter = withBaseElements(({ history, location }: Props) => {
  const user = useUser();
  switchLanguage(location, history);

// console.log('user', user)
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {(user.accessLevel === 'OWNER' || user.accessLevel === 'ADMIN') &&
          <Route path={ADMIN.delegates}>
            <Users />
          </Route>
        }

        {(user.accessLevel === 'OWNER' || user.accessLevel === 'ADMIN') &&
          <Route path={ADMIN.teams}>
            <Teams />
          </Route>
        }

        <Route path={ADMIN.team}>
          <TeamDetails />
        </Route>
        <Route path={ADMIN.company} component={Company} />
      </Switch>
    </Suspense>
  );
});

const PublicRouter = ({ history, location }: Props) => {
  switchLanguage(location, history);
  const websiteUrl = 'https://www.valital.com';

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path={PUBLIC.terms_of_use} component={Terms} />
        <Route path={PUBLIC.privacy_policy} component={Privacy} />
        <Route
          path={PUBLIC.website}
          component={() => {
            window.location.href =
              i18next.language === 'fr' ? `${websiteUrl}/fr` : websiteUrl;
          }}
        />
      </Switch>
    </Suspense>
  );
};

type Props = {
  location: Location,
  history: RouterHistory,
};

export default {
  AuthRouter,
  PublicRouter,
  PrivateRouter: requiresAuth(requiresPlan(PrivateRouter)),
  AdminRouter: requiresAuth(requiresPlan(AdminRouter, false), {
    authType: 'ADMIN',
    redirectTo: PRIVATE.dashboard,
  }),
};
