import { Dispatch, Action } from 'redux';
import axios from 'axios';
import { Location } from '../reducers/locations'
import { NotificationManager } from 'react-notifications';


// Action types
export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

interface GetLocationsRequest extends Action<typeof GET_LOCATIONS_REQUEST> { }
interface GetLocationsSuccess extends Action<typeof GET_LOCATIONS_SUCCESS> {
  payload: Location[]
}
interface GetLocationsFailure extends Action<typeof GET_LOCATIONS_FAILURE> {
  payload: string
}


export type ActionTypes = GetLocationsRequest | GetLocationsSuccess | GetLocationsFailure

// Action creators
const getLocationsRequest = (): GetLocationsRequest => ({ type: GET_LOCATIONS_REQUEST });
const getLocationsSuccess = (data: Location[]): GetLocationsSuccess => ({ type: GET_LOCATIONS_SUCCESS, payload: data });
const getLocationsFailure = (error: string): GetLocationsFailure => ({ type: GET_LOCATIONS_FAILURE, payload: error });


// Async action creators
const getLocations = (searchTerm = '') => {
  const token = window.localStorage.getItem('token');
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getLocationsRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/locations/all?search=${encodeURIComponent(searchTerm)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(getLocationsSuccess(response.data.locations || []));
    } catch (error: any) {
      console.error('Error fetching locations:', error);
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong');
      dispatch(getLocationsFailure(error.message));
    }
  };
};

const actions = {
  getLocations
}

export default actions
