export default {
  adverbs: {
    by: 'by',
    for_more_than_days: 'for more than {{count}} days',
    since: 'since',
    on: 'on',
    as_of: 'as of',
    as_in: 'as in',
    out_of: 'out of',
    per_month: 'per month',
  },
  nouns: {
    '2fa': 'Two-step verification',
    account: 'account',
    active: 'active',
    actives: 'active',
    active_users: 'active users',
    active_users_capitalized: 'Active users',
    addiction: 'addiction',
    address: {
      line_1: 'address line 1',
      line_2: 'address line 2',
      city: 'city',
      country: 'country',
      postal_code_zip: 'Postal code/ZIP',
      province_state: 'Province/State',
    },
    admin: 'admin',
    advanced: 'Advanced',
    all: 'All',
    annual_instant_validation: 'candidate reports',
    api_key: 'api key',
    available: 'complete',
    report: 'Report', 
    available_pending_twitter: 'Completed, pending Twitter verification',
    available_pending_articles: 'Completed, pending articles verification',
    basic_information: 'Basic information',
    preference_settings: 'Preference settings',
    billing: {
      billing: 'Billing',
      email: 'Billing email',
      billing_information: 'Billing information',
      billing_address: 'Billing address',
      next_payment: 'Next payment',
      payment_details: 'Payment details',
      payment_method: 'Payment method',
      save_changes: 'Save changes',
    },
    plans_and_payments: 'plans',
    city_of_residence: 'city of residence',
    clear: 'clear',
    clear_all: 'clear all filters',
    clear_sorting: 'Clear Sorting',
    coming_soon: 'Available at launch',
    company: 'organization',
    affiliated_organization: 'Affiliated organizations',
    company_name: 'Organization name',
    company_plural: 'organizations',
    conduct: 'Risk Indicator',
    report_type: 'Report type',
    connected_as: 'connected as',
    confirm_password: 'confirm your password',
    consent_status: 'invitation',
    consent_statuses: 'invitations',
    copyright: '© {{year}} Valital Technologies Inc.',
    sso: 'login with SSO',
    // create_password: 'create your password',
    credentials: 'credentials',
    custom: 'custom',
    custom_plan: '<span><strong>Custom</strong> plan</span>',
    dashboard: 'dashboard',
    delegate: 'user',
    delegate_1: 'a user',
    delegate_plural: 'users',
    department: 'department',
    discrimination: 'discrimination',
    done: 'done',
    downgrade: 'downgrade',
    email: 'email',
    end_date: 'End date',
    enrolment_period: 'Enrolment period',
    features: 'features',
    fraud: 'fraud',
    free: 'free',
    from: 'From',
    first_name: 'first name',
    global_results: 'global results',
    green: 'green',
    harassment: 'harassment',
    help_and_support: 'Help & Support',
    history: 'history',
    high_risk: 'high risk',
    identity_matching: 'identity matching',
    inactive_users: 'inactive users',
    individual: 'Individual',
    organization: 'Organization',
    individual_plural: 'Individuals',
    integrations: 'integrations',
    in: 'in',
    invite: 'invite',
    investigate: 'investigate',
    invoices: {
      invoices: 'Invoices',
      date: 'Date',
      invoice_number: 'Invoice #',
      status: 'Status',
      amount: 'Amount',
      statuses: {
        paid: 'paid',
        draft: 'draft',
        void: 'void',
        open: 'open',
        uncollectible: 'uncollectible',
      },
      last_payment: 'Last payment',
      payment_amount: 'Payment amount',
    },
    last_name: 'last name',
    last_request: 'last request',
    legal: 'legal',
    less: 'less',
    less_likely: 'less likely',
    likely: 'likely',
    live: 'live',
    prefrence_text:
      'Enable or disable LiveUpdate by default for all new validations',
    live_validation: 'LiveUpdate',
    validation_labels: 'Labels',
    live_validations: 'LiveUpdate',
    live_validations_enabled: 'LiveUpdate',
    live_validations_disabled: 'LiveUpdate disabled',
    include_twitter: 'Twitter',
    extend_search: 'Extended Search',
    not_trusted: 'Not trusted',
    location: 'Location',
    month: 'month',
    month_plural: 'months',
    most: 'most',
    most_likely: 'most likely',
    name: 'name',
    name_location: 'name & location',
    never: 'never',
    new: 'new',
    new_feminine: 'new',
    new_validation: 'New validation',
    news: 'news',
    next: 'next',
    new_instant_validation: 'Update Validation',
    new_password: 'new password',
    nick_name: 'Nickname (optional)',
    alternative_first_name: 'Alternative First Name (optional)',
    no: 'no',
    notable_themes: 'notable themes',
    notifications: 'notifications',
    off: 'off',
    ok: 'ok',
    old_password: 'old password',
    optional: 'optional',
    on: 'on',
    other: 'other',
    other_results: 'other results',
    password: 'password',
    code: 'code',
    pending_consent: 'pending invitation',
    pending_consent_plural: 'pending invitation',
    period_worked: 'period worked',
    personal_info: 'personal information',
    per_month: 'per month',
    phone_number: 'phone number',
    plan: 'plan',
    plans_for_every_org: 'Plans for Every Organization',
    plan_type: '{{planType}} plan',
    plan_validation_number: '<span><strong>Advanced</strong> features</span>',
    positions: 'organizations',
    previous: 'previous',
    pending_verification: 'Preliminary',
    pending_articles_verification: 'Pending articles verification',
    pending_twitter_verification: 'Pending twitter verification',
    new_twitter_validation: 'Run new validation <br />with Twitter',
    privacy_email: 'privacy@valital.com',
    privacy_statement: 'Privacy Statement',
    profile: 'profile',
    red: 'red',
    reference_1: 'a reference',
    reference: 'reference',
    references: 'references',
    ready_for_validation: 'Ready for validation',
    individuals_will_appear_here:
      'Your individuals to validate will appear here.',
    organizations_will_appear_here:
      'Your organizations to validate will appear here.',
    reminder: 'reminder',
    report: 'report',
    report_page: {
      idMatch: 'ID match',
      id_match: 'ID match',
      STRONG: 'STRONG',
      WEAK: 'WEAK',
      article: 'Article',
      article_plural: 'Articles',
      articles: 'Articles',
      LIVE: 'LIVE',
      INSTANT: 'INSTANT',
      source: 'Source',
      CREDIBLE: 'CREDIBLE',
      ALL: 'ALL',
      found_on: 'Found on',
    },
    request: 'request',
    reset_password: 'reset password',
    create_password: 'Create Password',
    risk_indicator: 'Risk indicator',
    risk: 'Risk',
    role: 'Title',
    role_name: 'Role or Position ',
    role_plural: 'Titles',
    row: 'row',
    row_plural: 'rows',
    security_settings: 'Security',
    selected: 'selected',
    select: 'select',
    settings: 'settings',
    start_date: 'Start Date',
    status: 'status',
    summary: 'summary',
    success: 'success',
    switch_language: 'français',
    talent: 'talent',
    talent_plural: 'talents',
    talent_SECURITY: 'individual',
    talent_SECURITY_plural: 'individuals',
    terms_of_use: 'Terms of Use',
    twitter: 'twitter',
    unavailable: 'unavailable',
    upgrade: 'upgrade',
    user: 'user',
    user_management: 'User management',
    user_plural: 'users',
    user_type: 'access type',
    validation: 'validation',
    validation_plural: 'validations',
    validation_date: 'last validation date',
    violence: 'violence',
    year: 'year',
    year_plural: 'years',
    yellow: 'yellow',
    yes: 'yes',
    quantity: 'Quantity',
    pricing_details: 'Pricing details',
    item: 'Item',
    unit_price: 'Unit price',
    amount: 'Amount',
    total: 'total',
    total_validation_period: 'Total validation period',
    validation_report: 'Report status',
    total_links: '{{totalLinks}} links',
    links: "links",
    verified_estimate: {
      verifying: 'Verifying...',
      within_hours: 'Verified within {{hours}}h',
      within_monday: 'Verified by Monday 9 am',
    },
    pdf_report: {
      header: 'Online Media Report for',
      powered_by: 'Powered by',
      view_on_twitter: 'View on Twitter',
      posted_on: 'Posted On',
      is_subject: 'is participant',
      not_subject: 'is mentioned',
      missconduct: 'Misconduct',
      found_on: 'Found On',
      from: 'From',
      links_analysed: 'Links Analyzed',
      created_by: 'Report created by {{name}}.',
      english: 'english',
      french: 'french',
      spanish: 'spanish',
      portuguese: 'portuguese',
      how_to_read_this_report: 'How to read this report',
    },
  },
  options: {
    role: {
      talent_acquisition: 'Talent Acquisition',
      executive: 'Executive / VP',
      hr_consultant: 'HR Consultant',
      other: 'Other',
    },
  },
  verbs: {
    accepted: 'accepted',
    add: 'add',
    add_individual: 'Add Individual',
    add_organization: 'Add Organization',
    add_without_company: 'Add without organization',
    added: 'added',
    apply: 'apply',
    archive: 'archive',
    archived: 'archived',
    back_to: 'back to',
    cancel: 'cancel',
    changed: 'changed',
    change_access_type: 'change access type',
    change_activity_status: 'change activity status',
    remove_access: 'Remove Access',
    grant_access: 'Grant Access',
    change_plan: 'change plan',
    change_plan_capitalized: 'Change plan',
    changed_plural: 'changed',
    close: 'close',
    confirmed: 'confirmed',
    contact_us: 'contact us',
    continue: 'continue',
    connect: 'connect',
    created_by: 'created by',
    deactivate: 'deactivate',
    decline: 'decline',
    declined: 'declined',
    declined_plural: 'declined',
    disable: 'disable',
    disconnect: 'disconnect',
    denied: 'Denied',
    update_privacy: 'Switch to ',
    delete: 'delete',
    edit: 'edit',
    enable: 'enable',
    enroll: 'enroll',
    filter: 'filter',
    finalize: 'finalize',
    finish: 'finish',
    get_started: 'get started',
    go_back: 'go back',
    invite: 'invite',
    in_progress: 'in progress',
    last_validated: 'last update',
    last_validated_on: 'Last update on',
    loading: 'loading',
    login: 'log in',
    login_with: 'log in with',
    logout: 'log out',
    new_individual: 'New Individual',
    new_organization: 'New Organization',
    pending: 'pending',
    published_on: 'Published on {{date}}',
    no_publish_date: 'No publish date',
    language: 'Language of the article',
    purchase: 'purchase',
    reactivate: 'reactivate',
    refused: 'declined',
    remind: 'remind',
    remove: 'remove',
    revoke: 'revoke',
    run_validation: 'run validation',
    send: 'send',
    sent: 'sent',
    show: 'Show',
    started: 'started',
    submit: 'submit',
    update: 'update',
    updated: 'Updated',
    updated_feminine: 'Updated',
    upgrade_plan: 'Upgrade plan',
    select_plan: 'Select a plan',
    upload_image: 'upload new image',
    update_individual: 'Update individual',
    update_organization: 'Update Organization',
    update_validation: 'Update Validation',
    validated: 'validated',
    validated_by: 'validated by',
    validated_feminine_plural: 'validated',
    validate_new_individual: 'Validate New Individual',
    validate_individual: 'Validate Individual',
    validate_new_organization: 'Validate New Organization',
    validate_organization: 'Validate Organization',
    view: 'view',
    cancel_subscription: 'Cancel subscription',
    add_to_plan: 'Add to plan',
    finalize_purchase: 'Finalize purchase',
    make_admin: 'Make admin',
    make_delegate: 'Make delegate',
    unarchive: 'Unarchive',
  },
  country: {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo (DRC)',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Cote d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curacao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Vatican City',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau',
    MK: 'North Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French part)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé and Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South sandwich islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Surinam',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Eswatini (Swaziland)',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Virgin Islands (British)',
    VI: 'Virgin islands (U.S.)',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
};
