import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import axios from 'axios';
import './index.css';
import { AppProvider } from 'utils/contexts/app-context';
import * as LocalStorage from 'utils/local-storage';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import store from './state/store';
import * as serviceWorker from './serviceWorker';
import 'utils/i18n';
import ErrorBoundary from './error-boundary';
import Targets from './utils/targets';
import FeatureFlags from './utils/feature-flags';
import App from './App';

const {
  REACT_APP_SERVER_URL = '',
  REACT_APP_NODE_SERVER_URL = ''
} = process.env;

// Axios
const baseURL: string = `${REACT_APP_NODE_SERVER_URL}/app`;
axios.defaults.baseURL = baseURL;

// Token
const initialToken = LocalStorage.getToken();
const initialRefreshToken = LocalStorage.getRefreshToken();

const defaultFlags = (target: string) => {
  const isSecurity = target === Targets.Security;
  const isRecruitment = target === Targets.Recruitment;

  return [
    { name: FeatureFlags.AllowRegister, isActive: isRecruitment },
    { name: FeatureFlags.AllowDashboard, isActive: isRecruitment },
    { name: FeatureFlags.AllowSearchAll, isActive: isRecruitment },
    { name: FeatureFlags.AllowTalents, isActive: isRecruitment },
    { name: FeatureFlags.AllowBilling, isActive: isRecruitment },
    { name: FeatureFlags.AllowIntegrations, isActive: isRecruitment },
    { name: FeatureFlags.AllowValidations, isActive: isSecurity },
    { name: FeatureFlags.AllowTwitter, isActive: isSecurity },
    { name: FeatureFlags.AllowReferences, isActive: isRecruitment },
  ];
};

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/security" // This will match any route not already defined
            render={({ location }) => {
              // Check if the path contains /security/app/
              if (location.pathname.includes('/security')) {
                // Redirect to /app, preserving query parameters
                const newPath = location.pathname.replace('/security', '') + location.search ;
                return <Redirect to={{ pathname: newPath,
                  hash: location.hash
                }} />;
              }
              // Otherwise, continue with normal routing
              return null;
            }}
          />
          <Route path={`/app`} >
            <Suspense fallback={<div>Loading...</div>}>
              <AppProvider
                initialToken={initialToken}
                initialRefreshToken={initialRefreshToken}
              >
                <App defaultFlags={defaultFlags(Targets.Security)} />
              </AppProvider>
            </Suspense>
          </Route>
          <Redirect to={{pathname:`/app`, hash: window.location.hash}} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
