import React, {lazy} from 'react';
import { connect } from 'react-redux';
import FlagsProvider from 'providers/FlagsProvider';

import { BrowserRouter, Redirect, Route, Switch,  useLocation } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { setDistanceToBottom } from 'state/ducks/window';

import ThemeProvider from 'providers/ThemeProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import {
  useRefreshToken,
  useToken,
  useTarget,
} from 'utils/contexts/app-context';
import { BillingProvider } from 'utils/contexts/billing-context';
import ScrollToTop from 'utils/ScrollToTop';
import { AUTH, PRIVATE, PUBLIC } from 'router/routes';
import Routers from 'router';

import 'App.scss';
import 'react-notifications/lib/notifications.css';

const App = ({ defaultFlags }: Props) => {
  const refreshToken = useRefreshToken();
  const token = useToken();
  const location = useLocation();

  const flags = [
    ...defaultFlags];

  const { AuthRouter, PublicRouter, PrivateRouter, AdminRouter } = Routers;

  return (
    <>

        <ThemeProvider>
          <ConfirmProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Switch>
                {(!refreshToken || !token) && (
                  <Route
                    exact
                    path={Object.values(AUTH)}
                    component={AuthRouter}
                  />
                )}

                {token &&
                  <Route
                    path="/app"
                    component={() => (
                      <BillingProvider>
                        <PrivateRouter />
                      </BillingProvider>
                    )}
                  />
                }

                {token &&

                  <Route
                    path="/admin"
                    component={() => (
                      <BillingProvider>
                        <AdminRouter />
                      </BillingProvider>
                    )}
                  />
                }

                <Route
                  exact
                  path={Object.values(PUBLIC)}
                  component={PublicRouter}
                />

                
                <Redirect to={{ pathname: (!refreshToken || !token) ? AUTH.login : "/app" ,
                  hash: location.hash,
                }} />
              </Switch>
            </BrowserRouter>

            <NotificationContainer />
          </ConfirmProvider>
        </ThemeProvider>
    </>
  );
};

type Props = {
  setDistanceToBottom: (distance: number) => void,
};

const mapDispatchToProps = dispatch => ({
  setDistanceToBottom(distance: number) {
    dispatch(setDistanceToBottom({ distance }));
  },
});

export default connect(null, mapDispatchToProps)(App);
